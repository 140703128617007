import React, {useEffect, useState, useCallback, useRef} from 'react'
import {css} from '@emotion/core'
import {fadeIn} from '../util/animation'

const SearchSuggest = ({
    searchFormSelector,
    searchInputId = 'q',
    suggestions = [],
    show = false,
    onClick = (e) => {
        e.stopPropagation()
    },
    themeStyles,
}) => {
    const suggestionDropdownRef = useRef(null)

    const [showSuggestedSearch, setShowSuggestedSearch] = useState(show)
    const [selectedSuggestionIdx, setSelectedSuggestionIdx] = useState(-1)

    const resetSearchCursor = useCallback(() => {
        setTimeout(() => {
            const inputEl = document.querySelector(searchFormSelector).querySelector(`#${searchInputId}`)
            if (inputEl) {
                inputEl.selectionStart = inputEl.selectionEnd = inputEl.value.length
            }
        }, 0)
    }, [searchFormSelector, searchInputId])

    const setSearchInput = useCallback(
        (val) => {
            const inputEl = document.querySelector(searchFormSelector).querySelector(`#${searchInputId}`)
            if (inputEl) {
                inputEl.value = val
            }
        },
        [searchFormSelector, searchInputId],
    )

    const onKeyDown = useCallback(
        (e) => {
            const {keyCode} = e
            const downCode = 40
            const upCode = 38
            const enterCode = 13
            if (keyCode === downCode) {
                if (selectedSuggestionIdx < suggestions.length - 1) {
                    setSelectedSuggestionIdx((curr) => {
                        const newIdx = curr + 1
                        const selectedOption = document.querySelector(`.search-suggestion--${newIdx}`)
                        if (selectedOption) {
                            const {suggestion} = selectedOption.dataset
                            setSearchInput(suggestion)
                        }
                        return newIdx
                    })
                }
            } else if (keyCode === upCode) {
                if (selectedSuggestionIdx >= 0) {
                    setSelectedSuggestionIdx((curr) => {
                        const newIdx = curr - 1
                        const selectedOption = document.querySelector(`.search-suggestion--${newIdx}`)
                        if (!selectedOption) {
                            resetSearchCursor()
                        } else {
                            const {suggestion} = selectedOption.dataset
                            setSearchInput(suggestion)
                            resetSearchCursor()
                        }
                        return newIdx
                    })
                } else {
                    setShowSuggestedSearch(false)
                    const inputEl = document.querySelector(searchFormSelector).querySelector(`#${searchInputId}`)
                    inputEl.focus()
                    resetSearchCursor()
                }
            } else if (keyCode === enterCode) {
                const selectedOption = document.querySelector(`.search-suggestion--${selectedSuggestionIdx}`)
                if (selectedOption) {
                    const {suggestion} = selectedOption.dataset
                    onClick(suggestion)
                }
            }
        },
        [
            onClick,
            resetSearchCursor,
            searchFormSelector,
            searchInputId,
            selectedSuggestionIdx,
            setSearchInput,
            suggestions.length,
        ],
    )

    // useEffect(() => {
    //   // reset selected index whenever search suggestions update
    //   setSelectedSuggestionIdx(-1);
    // }, [suggestions]);

    useEffect(() => {
        if (suggestions.length) {
            setShowSuggestedSearch(true)
            window.addEventListener('keydown', onKeyDown)
        }
        return () => {
            window.removeEventListener('keydown', onKeyDown)
        }
    }, [suggestions, selectedSuggestionIdx, onKeyDown])

    useEffect(() => {
        setShowSuggestedSearch(show)
    }, [show])

    const triggerFadeIn = suggestions.length && showSuggestedSearch

    return (
        <div
            className="search-suggest"
            ref={suggestionDropdownRef}
            css={css`
                transition: opacity 0.2s ease;
                border: 1px solid ${themeStyles.borderColor};
                border-radius: ${themeStyles.borderRadius};
                background-color: ${themeStyles.searchSuggestBackgroundColor};
                overflow: hidden;
                ${fadeIn(triggerFadeIn, 0.5)};
            `}
        >
            {suggestions.map((suggestion, i) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                    className={`search-suggestion--${i}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    role="button"
                    onClick={onClick}
                    css={css`
                        font-size: 15px;
                        padding: 0.5rem;
                        color: ${themeStyles.primaryTextColor};
                        background-color: ${selectedSuggestionIdx === i
                            ? themeStyles.searchSuggestBackgroundHoverColor
                            : 'transparent'};
                        &:hover {
                            cursor: pointer;
                            background-color: ${themeStyles.searchSuggestBackgroundHoverColor};
                        }
                    `}
                    tabIndex={-1}
                    data-suggestion={suggestion}
                >
                    {suggestion}
                </div>
            ))}
        </div>
    )
}

export default SearchSuggest
