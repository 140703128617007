import React, {useState, useCallback, useEffect, useRef} from 'react'
import {css} from '@emotion/core'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import {getLongLanguage} from '../../util/locale'
import {fadeIn} from '../../util/animation'
import SearchSuggest from '../SearchSuggest'
import useOnClickOutside from '../../util/useOnClickOutside'
import {useUid} from '../../util/useUid'
import {ENV_BASE_DOMAINS} from '../../env'
import {getStartpageBaseOrigin, getUrlParameterByName} from '../../util/url'

const SearchFormContainer = styled.div(
    ({theme, themeStyles, focused}) => css`
        position: relative;
        width: 100%;
        margin: 32px;
        max-width: 630px;
        color: ${themeStyles.primaryTextColor};
        background: ${themeStyles.searchSuggestBackgroundColor};
        border-radius: 2rem;
        border: 1px solid ${focused ? themeStyles.borderHover : themeStyles.borderColor};
        transition: border 0.2s ease;
        form {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
        }
        input,
        button {
            color: ${themeStyles.primaryTextColor};
            padding: 0;
            border: none;
            background-color: transparent;
        }
        span,
        button {
            &:hover {
                cursor: pointer;
            }
        }
        input {
            font-size: 15px;
            flex: 1;
            padding: 10px 75px 10px 15px;
            width: 100%;
            @media (max-width: 650px) {
                padding: 10px 70px 10px 15px;
            }
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: ${themeStyles.placeHolder};
                @media (max-width: 650px) {
                    color: transparent;
                    visibility: hidden;
                }
            }
        }
        .btns {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -10px;
        }
        .search-btn {
            background-image: url(${themeStyles.searchButtonUrl});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            height: 20px;
            width: 20px;
            display: block;
            margin-right: 0.7rem;
            &:hover {
                background-image: url(${themeStyles.searchButtonUrlHover});
                filter: ${theme === 'dark'
                    ? 'brightness(0) saturate(100%) invert(93%) sepia(3%) saturate(1004%) hue-rotate(199deg) brightness(102%) contrast(94%)'
                    : ''};
            }
        }
    `,
)

const SearchForm = ({theme, themeStyles, lang, inputId = 'q'}) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [suggestedSearchResults, setSuggestedSearchResults] = useState([])
    const [showSuggestedSearch, setShowSuggestedSearch] = useState(false)
    const [focused, setFocused] = useState(true)

    const searchSuggestEl = useRef(null)
    const searchBtn = useRef(null)
    const focusRef = useRef(null)

    // NOTE: this uid helps us have multiple search bars w/ search suggest on one page
    const uid = useUid()
    const searchFormClassName = `search-${uid}`

    const baseOrigin = getStartpageBaseOrigin()

    const getSuggestedSearch = async (e) => {
        const {value} = e.target
        setSearchQuery(value)
        if (value) {
            try {
                const resp = await fetch(
                    `${
                        ENV_BASE_DOMAINS.SUGGESTIONS_DOMAIN
                    }/suggestions?q=${value}&segment=startpage.udog&lui=${getLongLanguage()}`,
                )
                const results = await resp.json()
                const formatted = results.suggestions.map((suggestion) => suggestion.text)
                setSuggestedSearchResults(formatted)
            } catch (e) {
                console.log(e)
            }
        } else {
            setShowSuggestedSearch(false)
        }
    }

    const debouncedChangeHandler = useCallback(
        debounce((e) => {
            e.persist()
            getSuggestedSearch(e)
        }, 300),
        [],
    )

    const changeHandler = (e) => {
        setSearchQuery(e.target.value)
    }

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    useOnClickOutside(searchSuggestEl, (e) => {
        setShowSuggestedSearch(false)
    })

    useEffect(() => {
        focusRef.current.focus()
    }, [])

    const doSearch = () => {
        if (searchQuery) {
            const url = new URL(`${baseOrigin || window.location.origin}/do/search`)
            const {searchParams} = url
            const sc = getUrlParameterByName('sc')
            const params = {
                query: searchQuery.trim(),
                lui: getLongLanguage(),
            }
            if (sc) {
                params.sc = sc
            }
            for (const key in params) {
                if (Object.prototype.hasOwnProperty.call(params, key)) {
                    searchParams.set(key, params[key])
                }
            }
            window.location.href = url
        }
    }

    const onSuggestedSearchClick = (e) => {
        let suggestion
        if (typeof e === 'string') {
            suggestion = e
        } else {
            // @ts-expect-error: EventTarget has this stuff, don't fret
            suggestion = e.target.dataset.suggestion
        }
        setSearchQuery(suggestion)
        setFocused(true)
        setTimeout(() => {
            searchBtn.current.click()
        }, 0)
    }

    useEffect(() => {
        if (suggestedSearchResults.length > []) {
            setShowSuggestedSearch(true)
        } else {
            setShowSuggestedSearch(false)
        }
    }, [suggestedSearchResults])

    return (
        <SearchFormContainer theme={theme} themeStyles={themeStyles} focused={focused}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    doSearch()
                }}
            >
                <input
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    autoComplete="off"
                    id={inputId}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={(e) => {
                        e.persist()
                        debouncedChangeHandler(e)
                        changeHandler(e)
                    }}
                    className={searchFormClassName}
                    value={searchQuery}
                    ref={focusRef}
                />
                {searchQuery.length > 0 && (
                    <div
                        css={css`
                            padding-right: 10px;
                            margin-right: 10px;
                            border-right: 1px solid ${themeStyles.boxBorderColor};
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <button
                            css={css`
                                background-image: url(${baseOrigin}/sp/cdn/images/grey-x.svg);
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center center;
                                height: 20px;
                                width: 20px;
                            `}
                            onClick={() => {
                                setSearchQuery('')
                                focusRef.current.focus()
                            }}
                            aria-label="clear search input"
                            type="button"
                        />
                    </div>
                )}
                <div>
                    <button
                        type="submit"
                        onClick={doSearch}
                        id="search-btn"
                        className="search-btn"
                        aria-label="search button"
                        ref={searchBtn}
                    />
                </div>
            </form>
            <div
                className="suggested-search-container"
                css={css`
                    position: absolute;
                    top: 45px;
                    width: 100%;
                    ${fadeIn(showSuggestedSearch, 0.5)}
                `}
                ref={searchSuggestEl}
            >
                <SearchSuggest
                    searchFormSelector={`.${searchFormClassName}`}
                    searchInputId={inputId}
                    suggestions={suggestedSearchResults}
                    show={showSuggestedSearch}
                    onClick={onSuggestedSearchClick}
                    themeStyles={themeStyles}
                />
            </div>
        </SearchFormContainer>
    )
}

export default SearchForm
