import {useState, useEffect} from 'react';
import {css} from '@emotion/core'

export const useOnce = (callback) => {
    const [once, setOnce] = useState(false);
  
    useEffect(() => {
      if (!once) {
        callback();
        setOnce(true);
      }
    }, [once, callback]);
  };

export const useUid = () => {
    const [uid, setUid] = useState(css({ color: `${Math.random()}` }).name);
    useOnce(() => {
      setUid(css({ color: `${Math.random()}` }).name);
    });
    return uid;
  };
